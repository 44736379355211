.header {
    background: rgb(0, 0, 0);
    width: 100%;
    text-align: center;
    color: rgb(255, 255, 255);
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }
  
  .heading {
    font-size: 18px;
  }
  