.login-form {
    display: flex;
    justify-content: center;
    height: 100vh;
  }
  
  .reg-form {
    margin-top: 50px;
    margin-bottom: 50px;
    height: 500px;
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: #e7e7e7;
  }
  
  .login-form-input {
    padding: 5px;
    font-size: 16px;
    border: 1px solid rgb(255, 255, 255);
  }
  
.reg-form-btn {
    padding: 5px 10px;
    font-size: 16px;
    background-color: rgb(0, 0, 0);
    color: white;
    border: none;
  }
  
  .reg-form-btn:hover {
    cursor: pointer;
    background-color: rgb(44, 44, 44);
  }