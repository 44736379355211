html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}

body {
  line-height: 1;
  font-family: 'Roboto', sans-serif;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;

}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  box-sizing: border-box;
}

* {
  scrollbar-width: thin;
  scrollbar-color: rgb(150, 150, 150) rgb(34, 34, 34);
}

*::-webkit-scrollbar {
  width: 10px;
  height: 0px;
}

*::-webkit-scrollbar-track {
  background: rgb(197, 197, 197);
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(197, 197, 197);
  border-radius: 20px;
  border: 3px solid rgb(197, 197, 197);
}

* {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

textarea:focus,
input:focus,
button:focus {
  outline: none;
}

button:hover {
  cursor: pointer;
}

.chat {
  max-width: 500px;
  width: 100vw;
  height: calc(100vh);
  margin: 0px auto;
  background-color: rgb(197, 197, 197);
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}